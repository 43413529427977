import React from "react";

export default function ButtonIcon({ href, icon }) {
  return (
    <a
      href={href}
      className="flex items-center justify-center rounded-full bg-blueLight p-[.7rem]"
      target="_blank"
      rel="noreferrer"
      aria-label="icon"
    >
      {icon}
    </a>
  );
}
