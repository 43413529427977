import React, { useState } from "react";
import { BurgerIcon, CloseIcon } from "./Icons";
import { Link } from "react-scroll";

export default function Navigation() {
  const [active, setActive] = useState(0);
  const [click, setClick] = useState(false);
  const links = [
    { page: "home", idSection: "hero" },
    { page: "about" },
    { page: "projects" },
    { page: "contact" },
  ];
  return (
    <>
      <nav className="fixed left-0 top-0 z-[999] hidden w-full p-8 backdrop-blur-md lg:block">
        <ul className="flex items-center justify-center gap-52">
          {links.map((e, index) => {
            return (
              <li key={index}>
                <Link
                  to={e.idSection || e.page}
                  spy={true}
                  smooth={true}
                  offset={e.page === "about" ? -100 : -150}
                  duration={300}
                  delay={100}
                  className={`cursor-pointer capitalize ${
                    active === index ? "text-blue" : "font-light text-gray"
                  } hover:font-normal hover:text-blue`}
                  onClick={() => {
                    setActive(index);
                  }}
                >
                  {e.page}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
      <div className="fixed top-0 z-[999] flex w-full justify-end px-8 py-5 backdrop-blur-md lg:hidden">
        {click ? (
          <button
            className={`relative z-[999] flex h-8 w-8 items-end justify-end ${
              click ? "click" : ""
            }`}
            onClick={() => {
              setClick(false);
              document.body.style.overflow = "auto";
            }}
          >
            <CloseIcon className="relative bottom-1 h-5 w-5 fill-blue" />
          </button>
        ) : (
          <button
            className={`relative z-[999] h-8 w-8 ${click ? "click" : ""}`}
            onClick={() => {
              setClick(true);
              document.body.style.overflow = "hidden";
            }}
          >
            <BurgerIcon className="h-8 w-8 fill-blue" />
          </button>
        )}
      </div>
      <nav
        className={`nav fixed left-0 top-0 z-10 flex h-full w-full items-center justify-center bg-[#f9fbf4] p-8  lg:hidden
        ${click ? "click" : ""}`}
      >
        <ul className="flex flex-col gap-16">
          {links.map((e, index) => {
            return (
              <li key={index}>
                <Link
                  to={e.idSection || e.page}
                  spy={true}
                  smooth={true}
                  offset={e.page === "contact" ? -150 : -100}
                  duration={300}
                  delay={100}
                  className={`cursor-pointer capitalize ${
                    active === index ? "text-blue" : "font-light text-gray"
                  } hover:font-normal hover:text-blue`}
                  onClick={() => {
                    setActive(index);
                    setClick(false);
                    document.body.style.overflow = "auto";
                  }}
                >
                  {e.page}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </>
  );
}
