import React from "react";
import { BehanceIcon, LocationIcon, MailIcon, PhoneIcon } from "./Icons";
import ItemFooter from "./ItemFooter";
import { GitHubIcon, LinkedinIcon } from "./Icons";
import ButtonIcon from "./ButtonIcon";

export default function Footer() {
  return (
    <footer className="mt-[13rem] flex flex-col-reverse items-center gap-10 bg-blueLight p-8 text-sm font-medium text-black lg:mt-[20rem] lg:justify-between mxl:flex-row mxl:gap-0">
      <span className="text-blue">
        © 2024 Mustapha Ouasit. All rights reserved.
      </span>
      <div className="flex flex-col items-center gap-10 md:flex-row">
        <ItemFooter
          text="Casablanca, Morocco"
          icon={<LocationIcon className="h-[1.1rem] w-[1.1rem] fill-blue" />}
        />
        <ItemFooter
          text="mustaphaouasitdev@gmail.com"
          icon={<MailIcon className="h-[1.3rem] w-[1.3rem] fill-blue" />}
        />
        <ItemFooter
          text="06.03.12.17.35"
          icon={<PhoneIcon className="h-[1.1rem] w-[1.1rem] fill-blue" />}
        />
      </div>
      <div className="flex items-center gap-3">
        <ButtonIcon
          href="https://github.com/mouasit"
          icon={<GitHubIcon className="h-5 w-5 fill-blue" />}
        />
        <ButtonIcon
          href="https://www.behance.net/mustaphaouasit"
          icon={<BehanceIcon className="h-6 w-6 relative top-[2px] fill-blue" />}
        />
        <ButtonIcon
          href="https://www.linkedin.com/in/mustapha-ouasit-7290431a1/"
          icon={<LinkedinIcon className="h-5 w-5 fill-blue" />}
        />
      </div>
    </footer>
  );
}
