import React, { useState } from "react";
import CardProject from "./CardProject";
import dataProjects from "../dataProjects";
import { Link } from "react-scroll";

export default function Projects() {
  const numberItem = 4;
  const [count, setCount] = useState(numberItem);
  return (
    <section
      className="mt-[13rem] flex w-full flex-col items-center justify-center gap-24 lg:mt-[18rem] lg:gap-32"
      id="projects"
    >
      <span
        className="text-[2.2rem] font-bold capitalize text-blue
    "
      >
        my projects
      </span>
      <div className="w-full">
        <div className="flex flex-col gap-32">
          {dataProjects.map((e, index) => {
            if (index < count) return <CardProject data={e} key={index} />;
            return null;
          })}
        </div>
      </div>
      {count >= dataProjects.length ? (
        <Link
          to="projects"
          spy={true}
          smooth={true}
          offset={-100}
          duration={300}
          delay={100}
          className="cursor-pointer text-lg font-medium text-blue"
          onClick={() => {
            setCount(numberItem);
          }}
        >
          Show less
        </Link>
      ) : (
        <button
          className="text-lg font-medium text-blue"
          onClick={() => {
            setCount(count * 2);
          }}
        >
          View more
        </button>
      )}
    </section>
  );
}
