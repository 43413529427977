import React, { useState, useRef } from "react";
import { ExclamationIcon, SendIcon } from "./Icons";
import { isEmail, isMessage } from "../validationForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";
import { ArrowFly } from "./Icons";

export default function Contact() {
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const form = useRef();
  const [send, setSend] = useState(false);
  return (
    <>
      <ToastContainer autoClose={2000} />
      <section
        className="mt-[12rem] flex flex-col items-center justify-center gap-16 lg:mt-[25rem] lg:flex-row lg:justify-around lg:gap-[4.5rem] 2xl:justify-center 2xl:gap-[20rem]"
        id="contact"
      >
        <span className="relative  bottom-[4rem] text-[4rem] font-light text-blue">
          Let's Talk
        </span>
        <form
          ref={form}
          className="flex w-full flex-col gap-12 lg:relative lg:w-[40rem]"
        >
          <ArrowFly className="absolute right-0 top-[-18rem] hidden h-[17rem] w-[17rem] lg:block" />
          <div className="flex flex-col gap-1.5">
            <label className="text-sm text-black">Email</label>
            <input
              type="text"
              name="user_email"
              value={email}
              className={`rounded-md bg-grayLight p-4 text-sm text-black placeholder:text-xs placeholder:text-[#B2B2B1] focus:outline-none ${
                errorEmail.length ? "border-[1px] border-redError" : ""
              }`}
              placeholder="Enter your email"
              onChange={(e) => {
                setErrorEmail("");
                setEmail(e.currentTarget.value);
              }}
            />
            {errorEmail.length ? (
              <div className="flex items-center justify-end gap-1.5">
                <ExclamationIcon className="h-4 w-4 fill-redError" />
                <span className="text-sm font-medium text-redError">
                  {errorEmail}
                </span>
              </div>
            ) : null}
          </div>
          <div className="flex flex-col gap-1.5">
            <label className="text-sm text-black">Message</label>
            <textarea
              type="text"
              value={message}
              name="message"
              className={`h-[11rem] rounded-md bg-grayLight p-3 text-sm text-black placeholder:text-xs placeholder:text-[#B2B2B1] focus:outline-none ${
                errorMessage.length ? "border-[1px] border-redError" : ""
              }`}
              placeholder="Enter your message"
              onChange={(e) => {
                setErrorMessage("");
                setMessage(e.currentTarget.value);
              }}
            />
            {errorMessage.length ? (
              <div className="flex items-center justify-end gap-1.5">
                <ExclamationIcon className="h-4 w-4 fill-redError" />
                <span className="text-sm font-medium text-redError">
                  {errorMessage}
                </span>
              </div>
            ) : null}
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="flex w-48 items-center justify-center gap-6 rounded-md bg-blue p-3 font-medium text-white"
              onClick={(e) => {
                e.preventDefault();
                let error = false;
                const checkErrorEmail = isEmail(email);
                const checkErrorMessage = isMessage(message);

                if (checkErrorEmail.length) {
                  error = true;
                  setErrorEmail(checkErrorEmail);
                }
                if (checkErrorMessage.length) {
                  error = true;
                  setErrorMessage(checkErrorMessage);
                }
                if (!error) {
                  setSend(true);
                  emailjs
                    .sendForm(
                      // SERVICE_ID
                      "service_4wq6aqk",
                      //TEMPLATE_ID
                      "template_qvd1ysq",
                      form.current,
                      // YOUR_PUBLIC_KEY
                      "7r6nhpUbieBBZs0dL"
                    )
                    .then(
                      () => {
                        setEmail("");
                        setMessage("");
                        setSend(false);
                        toast.success("Your message sent successfully.", {
                          position: "top-right",
                          autoClose: 2000,
                          hideProgressBar: false,
                          closeOnClick: false,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        });
                      },
                      () => {
                        setEmail("");
                        setMessage("");
                        setSend(false);
                        toast.error("Your message doesn't send.", {
                          position: "top-right",
                          autoClose: 2000,
                          hideProgressBar: false,
                          closeOnClick: false,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        });
                      }
                    );
                }
              }}
              {...(send ? { disabled: true } : {})}
            >
              {send ? (
                <div
                  className="inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                ></div>
              ) : (
                <>
                  <span>Send</span>
                  <SendIcon className="h-5 w-5 fill-white" />
                </>
              )}
            </button>
          </div>
        </form>
      </section>
    </>
  );
}
