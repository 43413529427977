import React from "react";
import Info from "./Info";
import avatar from "../assets/avatar.png";
import palette from "../assets/palette.png";

export default function Hero() {
  return (
    <section
      className="pt-[6rem] flex flex-col items-center gap-16 lg:pt-[13vw] lg:flex-row lg:justify-around lg:gap-0 m2xl:pt-[16rem]"
      id="hero"
    >
      <Info />
      <div className="fadein-right relative w-[85vw] sm:w-[30rem] lg:w-[45%] 2xl:w-[40rem]">
        <img src={avatar} alt="avatar" />
        <img
          src={palette}
          alt="palette"
          className="arrow-animation absolute right-[4.5vw] top-[13.8vw] w-[16vw] sm:right-7 sm:top-[5rem] sm:w-[5.5rem] lg:right-[2.8vw] lg:top-[7vw] lg:w-[7.5vw] 2xl:right-9 2xl:top-[6.5rem] 2xl:w-[7.6rem]"
        />
      </div>
    </section>
  );
}
