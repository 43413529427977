import React from "react";
import { GitHubIcon, LinkedinIcon } from "./Icons";
import ButtonIcon from "./ButtonIcon";
import profile from "../assets/profile.jpg";

export default function About() {
  return (
    <section
      className="fadein-left mt-[10rem] flex flex-col items-center justify-around gap-16 lg:flex-row-reverse lg:mt-[18rem] 2xl:justify-center 2xl:gap-[17rem]"
      id="about"
    >
      <div className="flex flex-col items-center justify-center gap-10">
        <span className="text-[4rem] font-light  text-blue">Hi there !</span>
        <div className="flex flex-col  items-center gap-8">
          <p className="text-center text-sm font-light leading-[1.6rem] md:w-[40.5rem] md:text-[1rem] lg:w-[36rem] 2xl:w-[40.5rem]">
            I'm Mustapha Ouasit, a 26 year-old frontend developer who is
            passionate about creating engaging and intuitive websites. You can
            find me on GitHub and LinkedIn where I often share my latest
            projects and thoughts on frontend development. Let's work together
            to create something amazing!
          </p>
          <div className="flex gap-3">
            <ButtonIcon
              href="https://github.com/mouasit"
              icon={<GitHubIcon className="h-5 w-5 fill-blue" />}
            />
            <ButtonIcon
              href="https://www.linkedin.com/in/mustapha-ouasit-7290431a1/"
              icon={<LinkedinIcon className="h-5 w-5 fill-blue" />}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="relative flex h-[21rem] w-[18rem] justify-center bg-blue md:h-[27rem] md:w-[22rem]">
          <img
            src={profile}
            alt="profile"
            className="relative  left-[1.5rem] top-[1.5rem] h-[18rem] w-[18rem] md:left-[2.5rem] md:top-[2.5rem] md:h-[22rem] md:w-[22rem]"
          />
        </div>
      </div>
    </section>
  );
}
