import React from "react";

export default function Button({ href, text, icon, orderText }) {
  return (
    <a
      href={href}
      className={`animation-button button-item flex w-48 items-center justify-center gap-4 rounded-md bg-blue p-3 font-medium text-white ${
        orderText === "right" ? "flex-row-reverse" : "flex-row"
      }`}
      target="_blank"
      rel="noreferrer"
    >
      {text} {icon}
    </a>
  );
}