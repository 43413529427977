import React from "react";

export function BurgerIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="512"
      height="512"
      x="0"
      y="0"
      viewBox="0 0 512 512"
      style={{
        enableBackground: "0 0 512 512",
      }}
      xmlSpace="preserve"
      className={className}
    >
      <g>
        <path
          d="M128 102.4c0-14.138 11.462-25.6 25.6-25.6h332.8c14.138 0 25.6 11.462 25.6 25.6S500.538 128 486.4 128H153.6c-14.138 0-25.6-11.463-25.6-25.6zm358.4 128H25.6C11.462 230.4 0 241.863 0 256c0 14.138 11.462 25.6 25.6 25.6h460.8c14.138 0 25.6-11.462 25.6-25.6 0-14.137-11.462-25.6-25.6-25.6zm0 153.6H256c-14.137 0-25.6 11.462-25.6 25.6 0 14.137 11.463 25.6 25.6 25.6h230.4c14.138 0 25.6-11.463 25.6-25.6 0-14.138-11.462-25.6-25.6-25.6z"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
}

export function DownloadIcon({ className }) {
  return (
    <svg
      id="Group_5"
      data-name="Group 5"
      xmlns="http://www.w3.org/2000/svg"
      width="27.038"
      height="27.038"
      viewBox="0 0 27.038 27.038"
      className={className}
    >
      <path
        id="Path_2"
        data-name="Path 2"
        d="M21.03,27.038H6.008A6.009,6.009,0,0,1,0,21.03v-1.5a1.5,1.5,0,1,1,3,0v1.5a3,3,0,0,0,3,3H21.03a3,3,0,0,0,3-3v-1.5a1.5,1.5,0,1,1,3,0v1.5a6.009,6.009,0,0,1-6.009,6.009ZM13.519,21.03A1.5,1.5,0,0,1,12.47,20.6h0l0,0h0l0,0,0,0,0,0,0,0L6.448,14.581a1.5,1.5,0,0,1,2.124-2.124L12.017,15.9V1.5a1.5,1.5,0,1,1,3,0V15.9l3.444-3.444a1.5,1.5,0,1,1,2.124,2.124L14.581,20.59l0,0,0,0,0,0,0,0h0l0,0h0l-.05.047a1.492,1.492,0,0,1-.414.262h0a1.5,1.5,0,0,1-.582.117Z"
      />
    </svg>
  );
}

export function GitHubIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.366"
      height="27.984"
      viewBox="0 0 25.366 27.984"
      className={className}
    >
      <g id="Group_8" data-name="Group 8" transform="translate(-16.982 -10.8)">
        <path
          id="Path_3"
          data-name="Path 3"
          d="M24.745,38.784c.989,0,1.268-.387,1.268-.881s0-1.569-.021-3.1c-5.137,1.1-6.233-2.45-6.233-2.45A4.927,4.927,0,0,0,17.7,29.693c-1.676-1.118.129-1.1.129-1.1a3.912,3.912,0,0,1,2.837,1.87,3.942,3.942,0,0,0,5.373,1.5,3.843,3.843,0,0,1,1.182-2.45c-4.105-.451-8.425-2.02-8.425-8.984A6.981,6.981,0,0,1,20.7,15.657a6.329,6.329,0,0,1,.15-4.814s1.548-.494,5.094,1.87a17.736,17.736,0,0,1,9.242,0c3.5-2.343,5.072-1.87,5.072-1.87a6.681,6.681,0,0,1,.193,4.814,6.961,6.961,0,0,1,1.891,4.879c0,6.985-4.32,8.511-8.447,8.963a4.291,4.291,0,0,1,1.247,3.353c0,2.45-.021,4.385-.021,4.965,0,.473.215.86,1.268.86Z"
        />
      </g>
    </svg>
  );
}

export function BehanceIcon({ className }) {
  return (
    <svg
      version="1.1"
      x="0"
      y="0"
      viewBox="0 0 511.958 511.958"
      className={className}
    >
      <g>
        <path
          d="M210.624 240.619c10.624-5.344 18.656-11.296 24.16-17.728 9.792-11.584 14.624-26.944 14.624-45.984 0-18.528-4.832-34.368-14.496-47.648-16.128-21.632-43.424-32.704-82.016-33.28H0v312.096h142.56c16.064 0 30.944-1.376 44.704-4.192 13.76-2.848 25.664-8.064 35.744-15.68 8.96-6.624 16.448-14.848 22.4-24.544 9.408-14.656 14.112-31.264 14.112-49.76 0-17.92-4.128-33.184-12.32-45.728-8.288-12.544-20.448-21.728-36.576-27.552zM63.072 150.187h68.864c15.136 0 27.616 1.632 37.408 4.864 11.328 4.704 16.992 14.272 16.992 28.864 0 13.088-4.32 22.24-12.864 27.392-8.608 5.152-19.776 7.744-33.472 7.744H63.072v-68.864zm108.896 198.24c-7.616 3.68-18.336 5.504-32.064 5.504H63.072v-83.232h77.888c13.568.096 24.128 1.888 31.68 5.248 13.44 6.08 20.128 17.216 20.128 33.504 0 19.2-6.912 32.128-20.8 38.976zM327.168 110.539h135.584v38.848H327.168zM509.856 263.851c-2.816-18.08-9.024-33.984-18.688-47.712-10.592-15.552-24.032-26.944-40.384-34.144-16.288-7.232-34.624-10.848-55.04-10.816-34.272 0-62.112 10.72-83.648 32-21.472 21.344-32.224 52.032-32.224 92.032 0 42.656 11.872 73.472 35.744 92.384 23.776 18.944 51.232 28.384 82.4 28.384 37.728 0 67.072-11.232 88.032-33.632 13.408-14.144 20.992-28.064 22.656-41.728H446.24c-3.616 6.752-7.808 12.032-12.608 15.872-8.704 7.04-20.032 10.56-33.92 10.56-13.216 0-24.416-2.912-33.76-8.704-15.424-9.28-23.488-25.536-24.512-48.672h170.464c.256-19.936-.384-35.264-2.048-45.824zm-166.88 5.984c2.24-15.008 7.68-26.912 16.32-35.712 8.64-8.768 20.864-13.184 36.512-13.216 14.432 0 26.496 4.128 36.32 12.416 9.696 8.352 15.168 20.48 16.288 36.512h-105.44z"
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
}

export function LinkedinIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.097"
      height="24.097"
      viewBox="0 0 24.097 24.097"
      className={className}
    >
      <g id="Group_9" data-name="Group 9" transform="translate(-10 -10)">
        <path
          id="Path_4"
          data-name="Path 4"
          d="M34.1,34.1V25.271c0-4.337-.934-7.651-5.994-7.651a5.23,5.23,0,0,0-4.729,2.59h-.06v-2.2H18.524V34.1h5V26.115c0-2.108.392-4.127,2.982-4.127,2.56,0,2.59,2.38,2.59,4.247v7.831h5ZM10.392,18.012h5V34.1h-5ZM12.892,10a2.907,2.907,0,1,0,2.892,2.892A2.892,2.892,0,0,0,12.892,10Z"
        />
      </g>
    </svg>
  );
}

export function ArrowIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100.209"
      height="203.597"
      viewBox="0 0 100.209 203.597"
      className={className}
    >
      <path
        id="Path_1"
        data-name="Path 1"
        d="M59.269,217.268c13.28,4.854,27.135,2.373,39.956,6.969.308.11.324,1.028.738,2.5-2.465.506-4.874,1.484-7.248,1.406-10.95-.358-21.936-.56-32.819-1.689-10.309-1.069-12.8-5.812-9.079-15.559,3.394-8.9,6.973-17.73,10.545-26.559.382-.945,1.273-1.683,2.944-3.817,2.888,12.1-4.085,21.171-5.2,32.645,8.39-5.07,15.989-9.133,23.027-14.011,23.778-16.481,42.693-37.079,52.42-64.895,12.857-36.766,1.975-73.576-28.59-97.723a7.878,7.878,0,0,1-.679-.538c-2.051-2.018-6.847-3.455-3.565-7.139,2.944-3.306,5.691.54,8,2.384,36.663,29.316,46.754,74.428,25.965,116.568-13.363,27.087-34.562,46.56-60.516,61.24C69.972,211.988,64.571,214.54,59.269,217.268Z"
        transform="translate(-47.711 -26.052)"
        fill="#4778dc"
        stroke="#4979dd"
        strokeWidth="3"
      />
    </svg>
  );
}

export function LiveIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.337"
      height="24.337"
      viewBox="0 0 24.337 24.337"
      className={className}
    >
      <g id="Group_35" data-name="Group 35" transform="translate(-2 2.337)">
        <path
          id="Path_6"
          data-name="Path 6"
          d="M12.952,2H6.867A4.867,4.867,0,0,0,2,6.867v14.6a4.867,4.867,0,0,0,4.867,4.867h14.6a4.867,4.867,0,0,0,4.867-4.867V15.385a1.217,1.217,0,1,0-2.434,0v6.084A2.434,2.434,0,0,1,21.469,23.9H6.867a2.434,2.434,0,0,1-2.434-2.434V6.867A2.434,2.434,0,0,1,6.867,4.434h6.084a1.217,1.217,0,0,0,0-2.434Zm9.231,2.434H17.819a1.217,1.217,0,0,1,0-2.434h7.3a1.217,1.217,0,0,1,1.217,1.217v7.3a1.217,1.217,0,1,1-2.434,0V6.154l-8.874,8.874a1.217,1.217,0,1,1-1.721-1.721Z"
          transform="translate(0 -4.337)"
        />
      </g>
    </svg>
  );
}

export function SendIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.861"
      height="24.461"
      viewBox="0 0 23.861 24.461"
      className={className}
    >
      <g id="Group_61" data-name="Group 61" transform="translate(-4.961 0)">
        <path
          id="Path_7"
          data-name="Path 7"
          d="M5,23.344c-.2.924.508,1.315,1.166,1.02L28.4,12.961h0a.846.846,0,0,0,0-1.461h0L6.162.1C5.5-.2,4.794.192,5,1.116c.014.063,1.328,5.919,2.043,9.1l11.609,2.01L7.039,14.24C6.324,17.425,5.009,23.281,5,23.344Z"
          transform="translate(0 0)"
        />
      </g>
    </svg>
  );
}

export function CloseIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="512"
      height="512"
      x="0"
      y="0"
      viewBox="0 0 329.269 329"
      style={{ enableBackground: "0 0 512 512" }}
      xmlSpace="preserve"
      className={className}
    >
      <g>
        <path d="M194.8 164.77 323.013 36.555c8.343-8.34 8.343-21.825 0-30.164-8.34-8.34-21.825-8.34-30.164 0L164.633 134.605 36.422 6.391c-8.344-8.34-21.824-8.34-30.164 0-8.344 8.34-8.344 21.824 0 30.164l128.21 128.215L6.259 292.984c-8.344 8.34-8.344 21.825 0 30.164a21.266 21.266 0 0 0 15.082 6.25c5.46 0 10.922-2.09 15.082-6.25l128.21-128.214 128.216 128.214a21.273 21.273 0 0 0 15.082 6.25c5.46 0 10.922-2.09 15.082-6.25 8.343-8.34 8.343-21.824 0-30.164zm0 0"></path>
      </g>
    </svg>
  );
}

export function ExclamationIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20.789 20.789"
      className={className}
    >
      <g id="Group_484" data-name="Group 484" transform="translate(0 0)">
        <g id="Layer_1" transform="translate(0 0)">
          <path
            id="Path_106"
            data-name="Path 106"
            d="M11.395,1A10.395,10.395,0,1,0,21.789,11.395,10.4,10.4,0,0,0,11.395,1Zm0,16.632a1.386,1.386,0,1,1,1.386-1.386A1.39,1.39,0,0,1,11.395,17.632ZM12.982,6.883l-.464,5.558a1.127,1.127,0,0,1-2.245,0L9.808,6.883a1.593,1.593,0,1,1,3.181-.132.839.839,0,0,1-.007.132Z"
            transform="translate(-1 -1)"
          />
        </g>
      </g>
    </svg>
  );
}

export function ArrowFly({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="283.583"
      height="184.911"
      viewBox="0 0 283.583 184.911"
      className={className}
    >
      <g
        id="Group_69"
        data-name="Group 69"
        transform="translate(-94.443 -150.388)"
      >
        <g id="Group_68" data-name="Group 68">
          <path
            id="Path_8"
            data-name="Path 8"
            d="M276.727,221.992l12.129,37.1A3.935,3.935,0,0,0,295.1,260.9L331.327,231s-38.493-15.015-38.493-14.2S276.727,221.992,276.727,221.992Z"
            fill="#b5d1db"
          />
          <path
            id="Path_9"
            data-name="Path 9"
            d="M372.3,150.688c-23.657,9.356-100.013,40.646-128.914,52.5a4.415,4.415,0,0,0,.343,8.292l33,10.511,12.091,36.983a4,4,0,0,0,3.084,2.7c1.112.209,2.752-29.949,2.752-29.949S329.342,257.455,345.8,269.6a6.855,6.855,0,0,0,10.8-4.2c4.9-25.262,17.157-88.425,21.346-110.018A4.184,4.184,0,0,0,372.3,150.688Z"
            fill="#4879dd"
          />
          <path
            id="Path_10"
            data-name="Path 10"
            d="M291.9,261.678c1.112.209,2.752-29.949,2.752-29.949L351.642,178.2a.441.441,0,0,0-.528-.7l-74.387,44.493,12.091,36.983A4,4,0,0,0,291.9,261.678Z"
            fill="#d6e2ea"
          />
        </g>
        <path
          id="Path_11"
          data-name="Path 11"
          d="M94.446,334.3c39.142-.12,91.175-22.192,101.619-64.574,4.214-17.1.467-35.429-13.088-47.912-17.131-15.774-35.94-1.36-31.312,20.657,7.6,36.169,51.777,62.953,85.352,63.331,19.4.219,37.61-9.086,44.586-28.1"
          fill="none"
          stroke="#4879dd"
          strokeMiterlimit="10"
          strokeWidth="2"
          strokeDasharray="5"
        />
      </g>
    </svg>
  );
}

export function LocationIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.378"
      height="27.171"
      viewBox="0 0 20.378 27.171"
      className={className}
    >
      <g
        id="Group_84"
        data-name="Group 84"
        transform="translate(-17.742 -14.193)"
      >
        <g
          id="Group_82"
          data-name="Group 82"
          transform="translate(17.742 14.194)"
        >
          <path
            id="Path_12"
            data-name="Path 12"
            d="M13.189,0A10.238,10.238,0,0,0,3,10.263c0,8.041,9.231,16.344,9.624,16.693a.848.848,0,0,0,1.13,0c.393-.35,9.624-8.653,9.624-16.694A10.238,10.238,0,0,0,13.189,0Zm0,15.849a5.661,5.661,0,1,1,5.661-5.661A5.667,5.667,0,0,1,13.189,15.849Z"
            transform="translate(-3)"
          />
        </g>
      </g>
    </svg>
  );
}

export function MailIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.963"
      height="20.194"
      viewBox="0 0 26.963 20.194"
      className={className}
    >
      <g id="Group_91" data-name="Group 91" transform="translate(0 -64.266)">
        <path
          id="Path_14"
          data-name="Path 14"
          d="M15.735,178.9a4.056,4.056,0,0,1-4.507,0L.18,171.529q-.091-.061-.18-.127v12.07a2.481,2.481,0,0,0,2.482,2.482h22a2.481,2.481,0,0,0,2.482-2.482V171.4q-.088.066-.18.128Z"
          transform="translate(0 -101.494)"
          fill="#4879dd"
        />
        <path
          id="Path_15"
          data-name="Path 15"
          d="M1.056,68.721,12.1,76.087a2.476,2.476,0,0,0,2.754,0l11.049-7.366a2.366,2.366,0,0,0,1.056-1.974,2.484,2.484,0,0,0-2.481-2.481h-22A2.484,2.484,0,0,0,0,66.749a2.365,2.365,0,0,0,1.056,1.972Z"
          fill="#4879dd"
        />
      </g>
    </svg>
  );
}

export function PhoneIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27.115"
      height="27.172"
      viewBox="0 0 27.115 27.172"
      className={className}
    >
      <g id="Group_86" data-name="Group 86" transform="translate(-0.539)">
        <path
          id="Path_13"
          data-name="Path 13"
          d="M26.943,19.941l-3.792-3.792a2.522,2.522,0,0,0-4.2.948,2.581,2.581,0,0,1-2.979,1.625c-2.709-.677-6.365-4.2-7.042-7.042A2.454,2.454,0,0,1,10.556,8.7,2.522,2.522,0,0,0,11.5,4.5L7.713.711a2.7,2.7,0,0,0-3.656,0L1.483,3.284c-2.573,2.709.271,9.886,6.636,16.251S21.661,28.88,24.37,26.171L26.943,23.6a2.7,2.7,0,0,0,0-3.656Z"
          transform="translate(0)"
        />
      </g>
    </svg>
  );
}

export function ArrowToolTipIcon({ className }) {
  return (
    <svg version="1.1" x="0" y="0" viewBox="0 0 64 64" className={className}>
      <g>
        <path
          d="M54.921 20.297a1 1 0 0 0-.92-.611H10a1 1 0 0 0-.718 1.698l22 22.627a1 1 0 0 0 1.434 0l22-22.628a1 1 0 0 0 .204-1.086z"
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
}
