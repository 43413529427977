export function isEmail(email) {
  if (!email.trim().length) return "Zone text empty.";
  const emailFormat =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;

  if (!emailFormat.test(email)) return "Invalid email format.";
  return "";
}

export function isMessage(message) {
  if (!message.trim().length) return "Zone text empty.";
  if (message.length < 10) return "Minimum 10 characters";
  return "";
}
