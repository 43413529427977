import React from "react";

export default function ItemFooter({ text, icon }) {
  return (
    <div className="flex items-center gap-2">
      {icon}
      <span>{text}</span>
    </div>
  );
}
