import MultiStepForm from "./assets/imagesProjects/multi-step-form.jpg";
import EcommercePicture from "./assets/imagesProjects/e-commerce.jpg";
import NotificationsPicture from "./assets/imagesProjects/notifications.jpg";
import EasyBankPicture from "./assets/imagesProjects/easybank.jpg";
import ftTranscendence from "./assets/imagesProjects/ftTranscendence.jpg";
import FyloPicture from "./assets/imagesProjects/fylo.jpg";
import GolazoPicture from "./assets/imagesProjects/golazo.jpg";
import CardPicture from "./assets/imagesProjects/card.jpg";
import ToggelPicture from "./assets/imagesProjects/toggle.jpg";
import RatingPicture from "./assets/imagesProjects/rating.jpg";
import EcommerceWebsitePicture from "./assets/imagesProjects/ecommerce-website.png";

import Html from "./assets/tools/html.png";
import Css from "./assets/tools/css.png";
import JavaScript from "./assets/tools/javascript.png";
import TypeScript from "./assets/tools/typescript.png";
import ReactJs from "./assets/tools/react-js.png";
import Tailwind from "./assets/tools/tailwind-css.png";
import Chakra from "./assets/tools/chakra.png";

const dataProjects = [
  {
    name: "Ecommerce Website",
    picture: EcommerceWebsitePicture,
    description:
      "The E-commerce Website project offers a seamless shopping experience with a modern and responsive interface. Users can browse products, add items to their cart, choose different variants, and complete their purchase with a smooth checkout process. The website adapts to various screen sizes and ensures a visually appealing and user-friendly interaction.",
    tools: [TypeScript, ReactJs, Tailwind],
    repo: "null",
    demo: "https://mouasit.github.io/ecommerce-website",
  },
  {
    name: "Multi-step form",
    picture: MultiStepForm,
    description:
      "The Multi-Step Form project enhances data collection with a user-friendly interface. Users can complete each step, go back for updates, and confirm their order with a final summary. The form adapts to different screen sizes, offers interactive elements, and provides comprehensive validation for missed fields and more.",
    tools: [ReactJs, Tailwind],
    repo: "https://github.com/mouasit/Multi-step-form",
    demo: "https://mouasit.github.io/Multi-step-form",
  },
  {
    name: "E-commerce product page",
    picture: EcommercePicture,
    description:
      "The E-commerce Product Page is a responsive web application that allows users to view and purchase products online. The application features an optimal layout that adjusts to the user's device screen size.",
    tools: [ReactJs, Tailwind, Chakra],
    repo: "https://github.com/mouasit/E-commerce-product-page",
    demo: "https://mouasit.github.io/E-commerce-product-page",
  },

  {
    name: "Ft_transcendence",
    picture: ftTranscendence,
    description:
      "This is a group (4) project which is about creating a website for the mighty Pong contest! where things like: Security concerns - User Account - Chat - Game are the main things in the project. I was responsible for the front end part using: REACT JS - TAILWIND CSS - CHAKRA UI and Typescript as a must-use programming language.",
    tools: [TypeScript, ReactJs, Tailwind, Chakra],
    repo: "https://github.com/mouasit/ft_transcendence_demo",
    demo: "https://mouasit.github.io/ft_transcendence_demo",
  },

  {
    name: "Easybank landing page master",
    picture: EasyBankPicture,
    description:
      "Easybank Landing Page Master is a responsive web project designed to showcase skills in creating interactive web elements. The landing page allows users to view the optimal layout of the site based on their device's screen size and see hover states for all interactive elements on the page.",
    tools: [Html, Css, JavaScript],
    repo: "https://github.com/mouasit/easybank-landing-page-master",
    demo: "https://mouasit.github.io/easybank-landing-page-master",
  },

  {
    name: "Fylo dark theme landing page",
    picture: FyloPicture,
    description:
      "Fylo dark theme landing page is a responsive web project designed to showcase skills in creating interactive web elements. The landing page allows users to view the optimal layout of the site based on their device's screen size.",
    tools: [Html, Css, JavaScript],
    repo: "https://github.com/mouasit/Fylo-dark-theme-landing-page",
    demo: "https://mouasit.github.io/Fylo-dark-theme-landing-page",
  },

  {
    name: "Notifications page",
    picture: NotificationsPicture,
    description:
      "The Notifications Page is a web-based interface that allows users to manage their notifications by distinguishing between unread and read notifications, marking all notifications as read with a single click.",
    tools: [ReactJs, Tailwind],
    repo: "https://github.com/mouasit/notifications-page",
    demo: "https://mouasit.github.io/notifications-page",
  },

  {
    name: "Golazo - Theme World Cup 2022",
    picture: GolazoPicture,
    description:
      "I designed and created a dynamic website for streaming the 2022 Football World Cup, utilizing advanced APIs and various tools such as REACT JS, CSS to provide real-time updates.",
    tools: [ReactJs, Css],
    repo: "https://github.com/mouasit/golazo-theme-world-cup-2022",
    demo: "https://mouasit.github.io/golazo-theme-world-cup-2022/",
  },
  {
    name: "Interactive card details form main",
    picture: CardPicture,
    description:
      "This frontend challenge requires the creation of a responsive form where users can input their card details and see the card details update in real-time. Error messages should be provided for empty fields or incorrectly formatted input.",
    tools: [Html, Css, JavaScript],
    repo: "https://github.com/mouasit/interactive-card-details-form-main",
    demo: "https://mouasit.github.io/interactive-card-details-form-main/",
  },

  {
    name: "Pricing component with toggle",
    picture: ToggelPicture,
    description:
      "The Pricing Component with Toggle is a frontend challenge that requires creating a responsive component with a toggle that switches between monthly and yearly pricing plans.",
    tools: [Html, Css, JavaScript],
    repo: "https://github.com/mouasit/Pricing-component-with-toggle",
    demo: "https://mouasit.github.io/Pricing-component-with-toggle",
  },

  {
    name: "Interactive rating component",
    picture: RatingPicture,
    description:
      "The Interactive Rating Component is a frontend challenge that requires creating a component that allows users to select and submit a rating.",
    tools: [Html, Css, JavaScript],
    repo: "https://github.com/mouasit/Interactive-rating-component",
    demo: "https://mouasit.github.io/Interactive-rating-component",
  },
];

export default dataProjects;
