import React from "react";
import {
  ArrowIcon,
  BehanceIcon,
  DownloadIcon,
  GitHubIcon,
  LinkedinIcon,
} from "./Icons";
import ButtonCv from "./ButtonCv";
import ButtonIcon from "./ButtonIcon";

export default function Info() {
  return (
    <div className="fadein-left flex items-center">
      <div className="flex flex-col gap-10">
        <div className="flex flex-col gap-3 font-medium text-black">
          <span>Hi, I'm Mustapha,</span>
          <span className="text-[2.2rem] font-bold text-blue lg:text-[2.5rem]">
            frontend developer
          </span>
          <p className="leading-[2rem] sm:w-[30rem] md:text-center">
            specializing in creating visually appealing and responsive web
            interfaces. Check out my portfolio to see my latest projects and
            resume.
          </p>
        </div>
        <div className="flex flex-col items-center gap-8 ms:flex-row sm:justify-center">
          <ButtonCv
            text="My resume"
            icon={<DownloadIcon className="h-5 w-5 fill-white" />}
          />
          <div className="flex items-center gap-3">
            <ButtonIcon
              href="https://github.com/mouasit"
              icon={<GitHubIcon className="h-5 w-5 fill-blue" />}
            />
            <ButtonIcon
              href="https://www.behance.net/mustaphaouasit"
              icon={<BehanceIcon className="h-5 w-5 fill-blue" />}
            />
            <ButtonIcon
              href="https://www.linkedin.com/in/mustapha-ouasit-7290431a1/"
              icon={<LinkedinIcon className="h-5 w-5 fill-blue" />}
            />
          </div>
        </div>
      </div>
      <ArrowIcon className="hidden w-[5rem] md:block 2xl:w-auto" />
    </div>
  );
}
