import React from "react";

export default function Button({ text, icon, orderText }) {
  return (
    <a
      href="resume-mustapha-ouasit.pdf"
      download="resume-mustapha-ouasit.pdf"
      className={`animation-button button-item flex w-48 items-center justify-center gap-4 rounded-md bg-blue p-3 font-medium text-white ${
        orderText === "right" ? "flex-row-reverse" : "flex-row"
      }`}
    >
      {text} {icon}
    </a>
  );
}
