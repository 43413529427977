import React, { useState } from "react";
import ButtonLight from "../Components/ButtonLight";
import Button from "../Components/Button";
import { ArrowToolTipIcon, GitHubIcon, LiveIcon } from "./Icons";

export default function CardProject({ data }) {
  const [showToolTip, setShowToolTip] = useState(false);
  return (
    <div className="flex flex-col items-center gap-8 lg:flex-row lg:justify-center mmlg:gap-24">
      <img
        src={data.picture}
        alt="project-preview"
        className="w-[80vw] sm:w-[30rem]"
      />
      <div className="flex flex-col items-center gap-[2.5rem] lg:items-start">
        <span className="text-[1.4rem] font-bold text-black">{data.name}</span>
        <p className="text-center text-sm font-light leading-[1.5rem] md:w-[42rem] lg:w-auto lg:text-left lg:text-[1rem] mmlg:w-[42rem]">
          {data.description}
        </p>
        <div className="flex w-full flex-col items-center gap-[2rem] lg:items-start lg:justify-between mlg:flex-row mlg:items-center mlg:gap-0">
          <div className="relative flex gap-3">
            {data.tools.map((e, index) => {
              return <img src={e} alt={e} className="h-8 w-8" key={index} />;
            })}
          </div>
          <div className="mt-5 flex flex-col items-center gap-4 mmd:flex-row mlg:mt-0">
            {data.repo !== "null" ? (
              <ButtonLight
                href={data.repo}
                text="View Code"
                icon={<GitHubIcon className="h-5 w-5 fill-blue" />}
                orderText="right"
              />
            ) : (
              <div
                className="relative flex w-48 cursor-not-allowed flex-row-reverse items-center justify-center gap-4 rounded-md bg-blueLight p-3 font-medium text-blue"
                onMouseEnter={() => {
                  setShowToolTip(true);
                }}
                onMouseLeave={() => {
                  setShowToolTip(false);
                }}
              >
                View Code <GitHubIcon className="h-5 w-5 fill-blue" />
                {showToolTip ? (
                  <div className="fade-in absolute top-[-55px] w-full rounded-md bg-[#2b3440] p-2 text-center text-[.8rem]  font-light text-white">
                    Sorry,it's not open source
                    <div className="absolute left-0 top-[1.9rem] flex w-full justify-center">
                      <ArrowToolTipIcon className="h-4 w-4 fill-[#2b3440]" />
                    </div>
                  </div>
                ) : null}
              </div>
            )}
            <Button
              href={data.demo}
              text="Live Demo"
              icon={<LiveIcon className="h-5 w-5 fill-white" />}
              orderText="right"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
